import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { Package } from "./PackagesComponent/Package"
import { PackageForm } from "../../Components/Forms/PackageForm"
import { Helmet } from "react-helmet-async"

export const Packages = () => {
  return (
    <>
      <Helmet>
        <title>Packages</title>
        <link rel='canonical' href='/packages' />
        <meta
          name='description'
          content='Choose from a range of package plans tailored to meet your needs. Our carefully crafted plans offer comprehensive solutions designed to help you achieve your goals. Explore our packages, each featuring a unique set of services and benefits to cater to businesses of all sizes. From essential features to premium offerings, find the perfect plan to elevate your success.'
        />
      </Helmet>
      <Hero bgUrl='https://images.pexels.com/photos/669619/pexels-photo-669619.jpeg?' title='Packages' />
      <Package />
      <PackageForm />
    </>
  )
}
