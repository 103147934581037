import { useState } from "react"
import "./Styles/PackageForm.css"

export const PackageForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("")

  const handlePhoneNumberChange = event => {
    let input = event.target.value
    input = input.replace(/\D/g, "")
    input = input.substring(0, 10)

    if (input.length > 0) {
      if (input.length <= 3) {
        input = `(${input}`
      } else if (input.length <= 6) {
        input = `(${input.substring(0, 3)}) ${input.substring(3)}`
      } else {
        input = `(${input.substring(0, 3)}) ${input.substring(3, 6)}-${input.substring(6)}`
      }
    }

    input = input.replace(/(\d{3})\D*(\d{3})\D*(\d{4})/, "$1) $2-$3")
    setPhoneNumber(input)
  }

  return (
    <div className='packageFormContainer'>
      <h2 className='subtitle text-superWhite font-subtitle'>Contact us</h2>
      <p className='packageFormDescription'>
        Let us know a bit about your plan so we can set up a meeting via call to help you and guide you through the best package option for you
      </p>
      <form className='packageFormWrapper' action='https://formspree.io/f/xknlyqlw' method='POST'>
        <div className='formWrapper'>
          <label htmlFor='name'>Full Name:</label>
          <br />
          <input id='name' className='packageFormInput text-black' type='text' name='name' placeholder='e.g. John Doe' required />
          <label htmlFor='number'>Phone Number:</label>
          <input
            id='number'
            className='packageFormInput text-black'
            type='tel'
            name='number'
            value={phoneNumber}
            onChange={handlePhoneNumberChange}
            placeholder='(optional)'
          />
          <label htmlFor='email'>Email Address:</label>
          <input id='email' className='packageFormInput text-black' type='email' name='email' placeholder='example@email.com' required />
          <select name='packages' id='packages' className='text-black' required>
            <option value=''>Select Package</option>
            <option value='Basic Package'>Basic Package</option>
            <option value='Pro Package'>Pro Package</option>
            <option value='Elite Package'>Elite Package</option>
            <option value='Other'>Other</option>
          </select>
          <br />
          <label htmlFor='message'>Message:</label>
          <textarea
            className='packageFormMessage text-black'
            name='message'
            id='message'
            cols='30'
            rows='4'
            placeholder='Tell us a bit about your business...'
          ></textarea>
          <div className='btnWrapper'>
            <button className='packageFormBtn' type='submit'>
              Send
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}
