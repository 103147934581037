import React from "react"
import { Hero } from "./HomeComponents/Hero"
import { Services } from "./HomeComponents/Services"
import { WhatWeDo } from "./HomeComponents/WhatWeDo"
import { Helmet } from "react-helmet-async"
import { Portfolio } from "./HomeComponents/Portfolio"
import { SocialMedia } from "./HomeComponents/SocialMedia"

export const Home = () => {
  return (
    <>
      <Helmet>
        <title>ZenTech Media Solutions</title>
        <link rel='canonical' href='/' />
        {/* Favicon link */}
        <link rel='icon' href='https://zentechmediasolutions.com/static/media/zenTechLogo1.aceebcb831815d3d7ded.png' />

        {/* Apple Touch Icon link */}
        <link rel='apple-touch-icon' sizes='180x180' href='https://zentechmediasolutions.com/static/media/zenTechLogo1.aceebcb831815d3d7ded.png' />

        <meta property='og:type' content='website' />
        <meta property='og:title' content='ZenTech Tech Talks' />
        <meta
          property='og:description'
          content='At ZenTech Media Solution We Offer an Array of Web Development and Marketing Services to Meet Your Business Needs. From creating Landing Pages to full scale websites, we offer management and creation services.'
        />
        <meta property='og:image' content='https://zentechmediasolutions.com/static/media/zenTechLogo1.aceebcb831815d3d7ded.png' />
        <meta property='og:url' content='https://www.zentechmediasolutions.com/blog/home' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='ZenTech Tech Talks' />
        <meta
          name='twitter:description'
          content='At ZenTech Media Solution We Offer an Array of Web Development and Marketing Services to Meet Your Business Needs. From creating Landing Pages to full scale websites, we offer management and creation services.'
        />
        <meta name='twitter:image' content='https://zentechmediasolutions.com/static/media/zenTechLogo1.aceebcb831815d3d7ded.png' />
        <meta
          name='description'
          content='At ZenTech Media Solution We Offer an Array of Web Development and Marketing Services to Meet Your Business Needs. From creating Landing Pages to full scale websites, we offer management and creation services.'
        />
      </Helmet>
      <Hero />
      <Services />
      <WhatWeDo />
      <Portfolio />
      <SocialMedia />
    </>
  )
}
