// --------------WEB DEV DATA--------------------
// -----------------------------------------------
// -----------------------------------------------
export const WebDevData = [
  {
    // ---------------First Section-------------
    about:
      'Web development is the creation of a website that has the ability to be deployed and shown on the internet in order to reach a target audience.',
    subtitle: 'Why Hire Web Developers?',
    description: `When looking to attract an audience you need to know how to make your website aesthetic, legible, and how to make it search engine friendly. A web developer knows these things and will always incorporate it to the custom website from the very beginning. With ongoing maintanance you won't have to worry about staying up to date with the latest search engine optimization.`,
    img: 'https://images.pexels.com/photos/326514/pexels-photo-326514.jpeg?',
    alt: 'Web Development',
    // -------------Our Process list ------------
    aboutBackground: 'https://images.pexels.com/photos/354941/pexels-photo-354941.jpeg?',
    aboutTitle: 'Our Process',
    listItem1: 'We will make sure to walkthrough your vision in order for the website to attract the right audience.',
    listItem2: `Our Developers specialize in creating excellent user interfaces to attract users and in creating exceptional user experience to keep the
    user engaged.`,
    listItem3: `Our team is flexible and adaptable, we stay up to date with the website to maintain it in great shape.`,
    // ---------------ExtraServices----------------
    extraServices: [
      {
        service: 'Landing Pages',
        serviceRedirect: '/web-development/landing-pages',
      },
      {
        service: 'Search Engine Optimization',
        serviceRedirect: '/web-development/search-engine-optimization',
      },
      {
        service: 'Web Design',
        serviceRedirect: '/web-development/web-design',
      },
    ],
    name: 'Web Development',
  },
]
// -----------------MARKETING DATA----------------
// -----------------------------------------------
// -----------------------------------------------
export const MarketingData = [
  {
    // ---------------First Section-------------
    about: `Marketing is the process of identifying, anticipating, and satisfying customer needs and wants through the creation, promotion, and distribution of products or services.`,
    subtitle: `Why Does Your Business Need Marketing Experts?`,
    description: `Marketing is a crucial aspect of any business that aims to attract, retain, and grow its customer base. It involves understanding the target audience, developing a product or service that meets their needs, and communicating its benefits effectively. Marketing encompasses a wide range of activities, such as market research, branding, advertising, public relations, sales, and customer service. A successful marketing strategy can help a business differentiate itself from its competitors, increase its sales and revenue, and build long-term relationships with its customers.`,
    img: `https://images.unsplash.com/photo-1460925895917-afdab827c52f?`,
    alt: `Laptop with Charts`,
    // -------------Our Process list ------------
    aboutBackground: `https://images.unsplash.com/photo-1454165804606-c3d57bc86b40?`,
    aboutTitle: `Why Marketing is important`,
    listItem1: `Conduct a comprehensive market analysis to identify customer needs, competitor landscape, and market trends.`,
    listItem2: `Develop a data-driven marketing strategy that aligns with the business goals and target audience.`,
    listItem3: `Implement and optimize the marketing tactics, such as SEO, PPC, content marketing, social media, and email marketing, to reach and engage the target audience and drive conversions.
    `,
    // ---------------ExtraServices----------------
    extraServices: [
      {
        service: 'Social Media Management',
        serviceRedirect: '/marketing/social-media-management',
      },
      {
        service: 'Conversion Rate Optimization',
        serviceRedirect: '/marketing/conversion-rate-optimization',
      },
    ],
    name: 'Marketing',
  },
]
//   -----------------DIGITAL ASSETS DATA-----------
export const DigitalAssetsData = [
  {
    // ---------------First Section-------------
    about: `Digital assets are a variety of content that provides value to a business, person, or advertiser.`,
    subtitle: `What are digital assets?`,
    description: `Digital assets come in a plethora of ways. From videos to logos, all are created to achieve
    traffic, leads, and the next big sale. With our skills of marketing and design, we transform your
    business to look neat, stylish, and organized. Digital assets and marketing go hand and hand
    when it comes to promoting a product or brand. We look to service quality assets in orderly
    fashion, meanwhile you or your company may continue to work on important tasks.`,
    img: `https://images.unsplash.com/photo-1587440871875-191322ee64b0?`,
    alt: `Visual of a creating process`,
    // -------------Our Process list ------------
    aboutBackground: `https://images.unsplash.com/photo-1517048676732-d65bc937f952?`,
    aboutTitle: `How we help our clients`,
    listItem1: `We will be creating digital assets that are vital to your branding elements. Creating a flow
    between your look, and all platforms`,
    listItem2: `Provide design elements that capture your target audience, and build a trusted clientele.`,
    listItem3: `Recommend the most recent trends in audio, content creation, web design, and
    transpire it into your branding elements.`,
    // ---------------ExtraServices----------------
    extraServices: [
      {
        service: 'Branding Strategy',
        serviceRedirect: '/digital-assets/branding',
      },
    ],
    name: 'Digital Assets',
  },
]
