import React from "react"
import "../Styles/OurMissionIntro.css"
import ourMissionImg from "../../../Assets/zenTechOurMissionImg.jpeg"

export const OurMissionIntro = () => {
  return (
    <section>
      <h3 className='subtitle text-superWhite capitalize font-subtitle px-[10px]'>Let's reach your goals and create new ones, together.</h3>
      <div className='missionWrapper'>
        <p className='missionDescription px-[20px]'>
          As your long term growth partner we strive to make your business reach its full potential by strategizing ways to get further reach and
          maximize conversions.{" "}
        </p>
        <div className='missionOneHalf'>
          <img className='missionImg' src={ourMissionImg} alt='' />
        </div>
        <div className='missionOneHalf'>
          <p className='missionPlan'>
            Managing a business is no lightwork. That is why we want to make sure that you are in good hands so that you can focus on your clients
            while we help you get new ones.
          </p>
        </div>
      </div>
    </section>
  )
}
