import React from "react"
import "../Styles/Services.css"
import { Link } from "react-router-dom"
import logo from "../../../Assets/zenTechLogo1.png"

export const Services = () => {
  return (
    <>
      <section className='serviceContainer'>
        <h2 className='subtitle text-superWhite text-center Sfont-subtitle'>Our Services:</h2>
        {/* Marketing Section */}
        <div className='serviceWrapperLeft'>
          <Link to='/marketing'>
            <h3 className='tertiarySubtitle text-hotPink serviceItem font-secondary'>Marketing</h3>
            <div className='serviceDescriptionWrapper'>
              <p className='text-superWhite description serviceDescription font-subtitle'>
                We know a business only prospers if you put the work in, so allow us to handle your <span className='text-seaBlue'>Marketing</span>{" "}
                campaigns and give you ideas for how to grow and reach new and existing customers.
              </p>
              <img src={logo} alt='' className='descriptionImg'></img>
            </div>
          </Link>
        </div>
        {/* Web Development Section */}
        <div className='serviceWrapperRight'>
          <Link to='/web-development'>
            <h3 className='tertiarySubtitle text-hotPink serviceItem font-secondary'>Web Development</h3>
            <div className='serviceDescriptionWrapper'>
              <img src={logo} alt='' className='descriptionImg'></img>
              <p className='text-superWhite description serviceDescription font-subtitle'>
                With our Web Development team we are able to make custom <span className='text-seaBlue'>Websites</span> for your company to use as an
                extension of your brand and help reach new customers.
              </p>
            </div>
          </Link>
        </div>
        {/* Digital Assets Section */}
        <div className='serviceWrapperLeft'>
          <Link to='/digital-assets'>
            <h3 className='tertiarySubtitle text-hotPink serviceItem font-secondary'>Digital Assets</h3>
            <div className='serviceDescriptionWrapper'>
              <p className='text-superWhite description serviceDescription font-subtitle'>
                From Stock photos to Logo Creation, we can take care of your every <span className='text-seaBlue'>Digital</span> need We provide
                packages to satisfy the needs of your business.
              </p>
              <img src={logo} alt='' className='descriptionImg'></img>
            </div>
          </Link>
        </div>
      </section>
    </>
  )
}
