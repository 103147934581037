import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { OurMissionIntro } from "./OurMissionComponents/OurMissionIntro"
import { MeetOurTeam } from "./OurMissionComponents/MeetOurTeam"
import { Helmet } from "react-helmet-async"
import { LastSection } from "./OurMissionComponents/LastSection"

export const OurMission = () => {
  return (
    <>
      <Helmet>
        <title>About</title>
        <link rel='canonical' href='/our-mission' />
        <meta
          name='description'
          content='Our mission is to help small businesses grow and reach their full potential, we take pride in being long term partners with businesses that are willing to expand.'
        />
      </Helmet>
      <Hero title='About' bgUrl='https://images.pexels.com/photos/5795688/pexels-photo-5795688.jpeg?' />
      <OurMissionIntro />
      <MeetOurTeam />
      <LastSection />
    </>
  )
}
