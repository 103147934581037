import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { ExtraServicesTemplate } from "../../Components/ExtraServicesTemplate/ExtraServicesTemplate"
import { SocialMediaData } from "../../Data/ExtraServicesData"
import { Helmet } from "react-helmet-async"

export const SocialMedia = () => {
  return (
    <section>
      <Helmet>
        <title>Social Media Management</title>
        <link rel='canonical' href='/marketing/social-media-management' />
        <meta
          name='description'
          content='We know that social media is everchanging and that it can be tough to keep up with it without taking up hours to create and make posts. That is why our team provides social media management for Instagram, Twitter, Facebook, Tik Tok and more.'
        />
      </Helmet>
      <Hero
        title='Social Media Management'
        bgUrl='https://images.pexels.com/photos/147413/twitter-facebook-together-exchange-of-information-147413.jpeg?'
      />
      <ExtraServicesTemplate data={SocialMediaData} />
    </section>
  )
}
