export const PackagesData = [
  {
    title: "Basic Package",
    name: "Basic",
    price: "Starting at $650/month",
    description: `Our Basic package offers the essential features you need to get started. It comes with limited support and functionality, perfect for those on a budget.`,
    keyPoints: [
      "Website Maintenance",
      "Content Updates",
      "Basic on-page SEO to improve website visibility",
      "Security Monitoring",
      "Consultations",
      "Digital Marketing",
      "Ad Campaign Setup: Initial setup of online ad campaigns (Client manages the ads)",
      "Basic Copywriting: Simple content adjustments for ads and website",
      "Traffic Boost: Basic SEO for traffic enhancement",
      "Monthly Performance Report: A summary of website and marketing",
    ],
  },
  {
    title: "Pro Package",
    name: "Pro",
    price: "Starting at $1,600/month",
    description: `Our Pro package offers more advanced features and support to help you take your business to the next level. It includes more functionality and customization options to help you stand out.`,
    keyPoints: [
      "Website Maintenance",
      "Content Management",
      " SEO Strategy",
      "Security & Backup",
      "Consultations",
      "Digital Marketing",
      "Ad Campaign Management: Create and manage online ads (Client provides ad resources)",
      "Copywriting: Compelling content for ads and website",
      "Traffic Generation: Drive traffic through SEO and paid ads",
      "Analytics & Reporting: Monitor website and marketing performance",
      "Social Media Integration: Integration and strategy for social media",
      "Email Marketing: Develop and execute email campaigns if needed",
      "Monthly Performance Review: In-depth review and recommendations for continuous improvement",
    ],
  },
  {
    title: "Elite Package",
    name: "Elite",
    price: "Starting at $3,000/month",
    description: `Our Elite package is designed for those who want the best of the best. It offers premium features and support, along with personalised consulting and strategic guidance to help you achieve your business goals. It includes all advanced features of the Pro package, plus exclusive access to our team of experts.`,
    keyPoints: [
      "Full-Service Website Development",
      "Content Creation and Management",
      "Advanced SEO Strategy",
      "Advanced Security & Data Protection",
      "Strategic Consultations",
      "Comprehensive Digital Marketing",
      "Ad Campaign Management: Create and manage online ad campaigns",
      "Professional Copywriting: Expertly crafted content for ads and website",
      "Traffic Generation: Advanced SEO and targeted paid advertising",
      "Advanced Analytics & Reporting: Detailed insights into website and marketing performance",
      "Social Media Integration & Management: Integration, content creation, and community management",
      "Email Marketing Automation: Advanced email campaigns, automation, and analytics",
      "Conversion Rate Optimization: Ongoing efforts to boost website conversions and lead generation",
      "E-commerce Support: Enhanced e-commerce features and product management",
      "Custom Feature Development: one custom feature or enhancements per month",
    ],
  },
]
