import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { DigitalAssetsData } from "../../Data/PagesData"
import { DataTemplateAbout, DataTemplateIntro, DataTemplateCta, DataTemplateExtraServices } from "../PagesSetup"
import { Helmet } from "react-helmet-async"

export const DigitalAssets = () => {
  return (
    <>
      <Helmet>
        <title>Digital Assets</title>
        <link rel='canonical' href='/digital-assets' />
        <meta
          name='description'
          content='From taking pictures of your products to helping create branding strategies, our team is ready to serve your business to help it reach its potential'
        />
      </Helmet>
      <Hero title='Digital Assets' bgUrl='https://images.pexels.com/photos/569098/pexels-photo-569098.jpeg?' />
      {DigitalAssetsData.map(info => (
        <>
          <DataTemplateIntro about={info.about} subtitle={info.subtitle} img={info.img} alt={info.alt} description={info.description} />

          <DataTemplateAbout
            aboutTitle={info.aboutTitle}
            listItem1={info.listItem1}
            listItem2={info.listItem2}
            listItem3={info.listItem3}
            aboutBackground={info.aboutBackground}
          />
          <DataTemplateExtraServices data={DigitalAssetsData} />
          <DataTemplateCta />
        </>
      ))}
    </>
  )
}
