import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { AboutWebDev } from "./WebDevComponents/AboutWebDev"
import { Helmet } from "react-helmet-async"

export const WebDev = () => {
  return (
    <>
      <Helmet>
        <title>Web Development</title>
        <link rel='canonical' href='/web-development' />
        <meta
          name='description'
          content='Web Development is one of our core services that we provide at ZenTech. Our team focuses on creating websites that not only look good but are also effective.'
        />
      </Helmet>
      <Hero title='Web Development' bgUrl='https://images.pexels.com/photos/270360/pexels-photo-270360.jpeg?' />
      <AboutWebDev />
    </>
  )
}
