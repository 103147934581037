import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { DataTemplateAbout, DataTemplateCta, DataTemplateExtraServices, DataTemplateIntro } from "../PagesSetup"
import { MarketingData } from "../../Data/PagesData"
import { Helmet } from "react-helmet-async"

export const Marketing = () => {
  return (
    <>
      <Helmet>
        <title>Marketing</title>
        <link rel='canonical' href='/marketing' />
        <meta
          name='description'
          content='At ZenTech we help create marketing goals to help your business grow. We make sure to do research to maximize the potential of your business.'
        />
      </Helmet>
      <Hero title='Marketing' bgUrl='https://images.pexels.com/photos/14751274/pexels-photo-14751274.jpeg?' />
      {MarketingData.map(info => (
        <>
          <DataTemplateIntro about={info.about} subtitle={info.subtitle} img={info.img} alt={info.alt} description={info.description} />

          <DataTemplateAbout
            aboutTitle={info.aboutTitle}
            listItem1={info.listItem1}
            listItem2={info.listItem2}
            listItem3={info.listItem3}
            aboutBackground={info.aboutBackground}
          />
          <DataTemplateExtraServices data={MarketingData} />
          <DataTemplateCta />
        </>
      ))}
    </>
  )
}
