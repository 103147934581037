import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ArticleData from "../BlogData/Articles.json"

export const BlogListing = () => {
  const [blogData, setBlogData] = useState([])
  const [selectedTopic, setSelectedTopic] = useState("")

  const allTopics = Object.values(ArticleData).flatMap(item => item.topic)
  const uniqueTopics = [...new Set(allTopics)]
  useEffect(() => {
    setBlogData(ArticleData)
  }, [])
  const data = Object.keys(blogData)

  const handleTopicChange = event => {
    const selectedValue = event.target.value
    setSelectedTopic(selectedValue)

    const filteredData = Object.values(ArticleData).filter(item => {
      if (selectedValue === "") {
        return true // Show all articles when no topic is selected
      } else {
        // Check if the selected topic is one of the topics for the article
        return item.topic.includes(selectedValue)
      }
    })

    setBlogData(filteredData)
  }
  // function sortByCategory(){

  // }
  console.log("Topic", selectedTopic)

  return (
    <section className='mt-[15vh]'>
      <h2 className='subtitle text-white font-secondary scale-[1.7]'>All Articles</h2>
      <div className='w-fit mx-auto'>
        <label htmlFor='topicSelection' className='text-[#e8e8e8] font-bold'>
          Select a Topic:
        </label>
        <br />
        <select
          className='rounded-[5px] bg-[#3df8eb] px-[5px] my-[20px] '
          id='topicSelection'
          onChange={handleTopicChange}
          value={selectedTopic}
          name='topicSelection'
        >
          <option value=''>All Topics</option>
          {uniqueTopics?.map((topic, index) => {
            return (
              <option key={index} value={topic}>
                {topic}
              </option>
            )
          })}
        </select>
      </div>
      <div className='flex flex-wrap gap-[40px] w-[90%] mx-auto'>
        {data?.map(postId => {
          const post = blogData[postId]
          return (
            <div key={postId} className=' w-[100%] items-center md:w-[45%] md:mx-auto'>
              <Link to={`/blog/${postId}`}>
                <div className='flex items-center  border bg-[#0befff41] rounded-[20px] p-[15px]'>
                  <img
                    className='w-[30%]  h-[60px] object-cover md:h-[100px] rounded-[10px] lg:h-[120px] xl:h-[160px]'
                    src={post.thumbnail}
                    alt={post.thumbnailAlt}
                  />
                  <h2 className=' w-[65%] text-white mx-auto text-center underline'>{post.title}</h2>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
    </section>
  )
}
