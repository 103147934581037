import React from "react"
import ayala from "../../../Assets/Portfolio/ayala.png"
import daisysFlowers from "../../../Assets/Portfolio/daisysFlowers.png"
import dancu from "../../../Assets/Portfolio/DanCu.png"

export const Portfolio = () => {
  const images = [
    {
      image: ayala,
    },
    {
      image: daisysFlowers,
    },
    {
      image: dancu,
    },
  ]
  return (
    <section className='py-[40px]'>
      <h2 className='pb-[20px] text-[3rem] capitalize text-superWhite text-center font-subtitle'>sample work</h2>
      <div className='flex flex-wrap justify-center gap-[40px]'>
        {images.map(info => {
          return <img className='w-[280px] object-cover' key={info.image} src={info.image} alt='' />
        })}
      </div>
    </section>
  )
}
