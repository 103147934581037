import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { ExtraServicesTemplate } from "../../Components/ExtraServicesTemplate/ExtraServicesTemplate"
import { WebDesignData } from "../../Data/ExtraServicesData"
import { Helmet } from "react-helmet-async"

export const WebDesign = () => {
  return (
    <>
      <Helmet>
        <title>Web Design</title>
        <link rel='canonical' href='/web-development/web-design' />
        <meta
          name='description'
          content='Our team is ready to help create appealing websites that are not only effective but also look appealing to users.'
        />
      </Helmet>
      <Hero title='Web Design' bgUrl='https://images.pexels.com/photos/3153204/pexels-photo-3153204.jpeg?' />
      <ExtraServicesTemplate data={WebDesignData} />
    </>
  )
}
