import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import ArticleData from "../BlogData/Articles.json"

export const ArticleListing = () => {
  const [blogData, setBlogData] = useState([])
  const [selectedTopic] = useState("")

  useEffect(() => {
    setBlogData(ArticleData)
  }, [])
  const data = Object.keys(blogData).slice(-10)

  // function sortByCategory(){

  // }
  console.log("Topic", selectedTopic)

  return (
    <section className='my-[30px]'>
      <h2 className='subtitle text-white font-secondary'>Lastest 10 Articles</h2>
      <div className='flex flex-wrap gap-[40px] w-[90%] mx-auto'>
        {data?.map(postId => {
          const post = blogData[postId]
          return (
            <div key={postId} className=' w-[100%] items-center md:w-[45%] md:mx-auto'>
              <Link to={`/blog/${postId}`}>
                <div className='flex items-center  border bg-[#0befff41] rounded-[20px] p-[15px]'>
                  <img
                    className='w-[30%]  h-[60px] object-cover md:h-[100px] rounded-[10px] lg:h-[120px] xl:h-[160px]'
                    src={post.thumbnail}
                    alt={post.thumbnailAlt}
                  />
                  <h2 className=' w-[65%] text-white mx-auto text-center underline'>{post.title}</h2>
                </div>
              </Link>
            </div>
          )
        })}
      </div>
      <div>
        <Link to='/blog/listing'>
          <div className='text-white text-[1.3rem] w-fit mx-auto rounded-[10px] py-[5px] px-[10px] bg-[#060606cc] text-seaBlue hover:text-[#f890e7] transition mt-[20px]'>
            See All Articles
          </div>
        </Link>
      </div>
    </section>
  )
}
