import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { SEOData } from "../../Data/ExtraServicesData"
import { ExtraServicesTemplate } from "../../Components/ExtraServicesTemplate/ExtraServicesTemplate"
import { Helmet } from "react-helmet-async"

export const SearchEngineOptimization = () => {
  return (
    <>
      <Helmet>
        <title>Search Engine Optimization</title>
        <link rel='canonical' href='/web-development/search-engine-optimization' />
        <meta
          name='description'
          content='We know how important SEO can be and for that reason we make sure to provide services that target search engine optimization for your website.'
        />
      </Helmet>
      <Hero title='Search Engine Optimization' bgUrl='https://images.pexels.com/photos/270637/pexels-photo-270637.jpeg?' />
      <ExtraServicesTemplate data={SEOData} />
    </>
  )
}
