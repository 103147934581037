import { WebDevData } from '../../../Data/PagesData'
import { DataTemplateAbout, DataTemplateCta, DataTemplateExtraServices, DataTemplateIntro } from '../../PagesSetup'

export const AboutWebDev = () => {
  return (
    <>
      {WebDevData.map(info => (
        <>
          <DataTemplateIntro about={info.about} subtitle={info.subtitle} img={info.img} alt={info.alt} description={info.description} />
          <DataTemplateAbout
            aboutTitle={info.aboutTitle}
            listItem1={info.listItem1}
            listItem2={info.listItem2}
            listItem3={info.listItem3}
            aboutBackground={info.aboutBackground}
          />
          <DataTemplateExtraServices data={WebDevData} />
          <DataTemplateCta />
        </>
      ))}
    </>
  )
}
