import React, { useState } from "react"
import { WhatWeDoData } from "../../../Data/WhatWeDoData"
import { Link } from "react-router-dom"

export const WhatWeDo = () => {
  const [hover, setHover] = useState(-1)
  return (
    <section className='bg-[rgba(0,0,0,.4)] py-[5vh]'>
      <h3 className='text-[3rem] text-superWhite text-center font-subtitle'>Things we Do</h3>
      <div className='flex flex-wrap gap-[10px]  justify-center mt-[30px] md:gap-[40px] lg:gap-[30px]'>
        {WhatWeDoData.map((info, index) => (
          <Link to={info.url}>
            <div
              className='w-[280px]  h-[380px] border border-white mt-[20px] md:mt-0 py-[20px] px-[10px] rounded-[10px] slideUpTransition xl:w-[350px] xl:h-[420px] xl:mx-[20px] '
              onMouseEnter={() => setHover(index)}
              onMouseLeave={() => setHover(-1)}
            >
              <img src={hover === index ? info.imgHover : info.img} alt={info.alt} className='w-[80px] mx-auto' />
              <h3 className='text-[1.6rem] text-center mt-[20px] font-secondary font-bold xl:text-[2rem]'>{info.title}</h3>
              <p className='text-center mt-[20px] font-subtitle text-[.8rem] xl:text-[1.1rem]'>{info.description}</p>
            </div>
          </Link>
        ))}
      </div>
    </section>
  )
}
