import { useEffect, useState } from "react"
import "./Navbar.css"
import { Link } from "react-router-dom"
import Logo from "../../Assets/zenTechLogo1.png"

export const Navbar = () => {
  const [viewportWidth, setViewportWidth] = useState(null)
  const [toggle, setToggle] = useState(false)
  function handleClick() {
    setToggle(!toggle)
  }
  function handleResize() {
    setViewportWidth(window.innerWidth)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  function MobileOrDesktop() {
    if (viewportWidth < 1024) {
      return (
        <nav className='navbarMobile'>
          <Link to='/' className='logoWrapper'>
            <img src={Logo} className='logo' alt='Zen Tech Logo' />
          </Link>
          <div>
            <div className={toggle ? "hamburgerX" : "hamburger"} onClick={() => handleClick()}></div>
            {toggle ? (
              <ul className='nav-containerMobile '>
                <Link to='/'>
                  <li onClick={() => handleClick()} className='navItemMobile'>
                    Home
                  </li>
                </Link>
                <Link to='/packages'>
                  <li onClick={() => handleClick()} className='navItemMobile'>
                    Packages
                  </li>
                </Link>
                <Link to='/marketing'>
                  <li onClick={() => handleClick()} className='navItemMobile'>
                    Marketing
                  </li>
                </Link>
                <Link to='/web-development'>
                  <li onClick={() => handleClick()} className='navItemMobile'>
                    Web Development
                  </li>
                </Link>
                <Link to='/digital-assets'>
                  <li onClick={() => handleClick()} className='navItemMobile'>
                    Digital Assets
                  </li>
                </Link>
                <Link to='/blog/home'>
                  <li onClick={() => handleClick()} className='navItemMobile'>
                    Blog
                  </li>
                </Link>
                <Link to='/about'>
                  <li onClick={() => handleClick()} className='navItemMobile'>
                    About
                  </li>
                </Link>
              </ul>
            ) : (
              ""
            )}
          </div>
        </nav>
      )
    } else {
      return (
        <nav className='navbar'>
          <Link to='/' className='logoWrapper'>
            <img src={Logo} alt='ZenTech Logo' className='logo' />
          </Link>
          <ul className='nav-container  font-bold'>
            <Link to='/packages'>
              <li className='navItem'>Packages</li>
            </Link>
            <li className='navItemToggle' onClick={() => handleClick()}>
              Services {toggle ? <span>&#9650;</span> : <span>&#9660;</span>}
            </li>
            {toggle ? (
              <ul className='navItemToggled' onClick={() => handleClick()}>
                <Link to='/marketing'>
                  {" "}
                  <li>Marketing</li>
                </Link>
                <Link to='/web-development'>
                  <li>Web Development</li>
                </Link>
                <Link to='/digital-assets'>
                  <li>Digital Assets</li>
                </Link>
              </ul>
            ) : (
              ""
            )}
            <Link to='/blog/home'>
              <li className='navItem'>Blog</li>
            </Link>
            <Link to='/about'>
              <li className='navItem'>About</li>
            </Link>
          </ul>
          <div className='navContact  font-bold'>
            <Link className='navCta' to='/contact-us'>
              Contact Us
            </Link>
          </div>
        </nav>
      )
    }
  }
  return <MobileOrDesktop />
}
