import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { ExtraServicesTemplate } from "../../Components/ExtraServicesTemplate/ExtraServicesTemplate"
import { SalesOptimizationData } from "../../Data/ExtraServicesData"
import { Helmet } from "react-helmet-async"

export const SalesOptimization = () => {
  return (
    <>
      <Helmet>
        <title>Sales Optimization</title>
        <link rel='canonical' href='/marketing/conversion-rate-optimization' />
        <meta
          name='description'
          content='We help you get more than just views on your website or social media. We make sure to create a strategy that help convert those views into potential customers.'
        />
      </Helmet>
      <Hero title='Sales Optimization' bgUrl='https://images.pexels.com/photos/95916/pexels-photo-95916.jpeg?' />
      <ExtraServicesTemplate data={SalesOptimizationData} />
    </>
  )
}
