import { BlogHero } from "./BlogHomeComponents/BlogHero"
import { ArticleListing } from "./BlogHomeComponents/ArticleListing"
import { Helmet } from "react-helmet"
import { FeaturedArticles } from "./BlogHomeComponents/FeaturedArticles"

export const BlogHome = () => {
  return (
    <div>
      <div className='mt-[10vh] min-h-[90vh]'>
        <BlogHero />
        <FeaturedArticles />
        <ArticleListing />
      </div>
      <Helmet>
        <title>ZenTech Tech Talks</title>
        <link rel='canonical' href='https://zentechmediasolutions.com/#/blog/home' />
        <meta
          name='description'
          content='Welcome to ZenTech Tech Talks where we share our views for everything tech. We want to make sure that you stay up to date with this everchanging digital world.'
        />
        <link rel='icon' href='https://zentechmediasolutions.com/static/media/coolTech.f1ea9581ef1b82b3c0b5.png' />
        <link rel='apple-touch-icon' href='https://zentechmediasolutions.com/static/media/coolTech.f1ea9581ef1b82b3c0b5.png' />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='ZenTech Tech Talks' />
        <meta
          property='og:description'
          content='Welcome to ZenTech Tech Talks where we share our views for everything tech. From software to hardware. We want to make sure that you stay up to date with this everchanging digital world.'
        />
        <meta property='og:image' content='https://zentechmediasolutions.com/static/media/coolTech.f1ea9581ef1b82b3c0b5.png' />
        <meta property='og:url' content='https://www.zentechmediasolutions.com/blog/home' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='ZenTech Tech Talks' />
        <meta
          name='twitter:description'
          content='Welcome to ZenTech Tech Talks where we share our views for everything tech. From software to hardware. We want to make sure that you stay up to date with this everchanging digital world.'
        />
        <meta name='twitter:image' content='https://zentechmediasolutions.com/static/media/coolTech.f1ea9581ef1b82b3c0b5.png' />
      </Helmet>
    </div>
  )
}
