import { Link } from "react-router-dom"
import "./PagesData.css"
import { useEffect, useState } from "react"
export const DataTemplateIntro = props => {
  // <DataTemplate about='' subtitle='' img='' alt='' description='' />
  return (
    <div className='aboutPageContainer'>
      <p className='aboutPageDescription text-center'>
        <span className='quickAnswer'>Quick Answer: </span>
        <br />
        {props.about}
      </p>
      <div className='whyAboutPageContainer'>
        <h4 className='subtitle text-superWhite'>{props.subtitle}</h4>
        <div className='leftSideAbout'>
          <p className='leftSideAboutDescription'>{props.description}</p>
        </div>
        <div className='rightSideAbout'>
          <img src={props.img} alt={props.alt} className='rightSideImg' />
        </div>
      </div>
    </div>
  )
}

export const DataTemplateAbout = props => {
  let style = {
    background: `url(${props.aboutBackground})`,
    "background-size": "cover",
    "background-attachment": "fixed",
  }
  let overlayStyle = {
    content: "",
    display: "block",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    "z-index": -10,
    background: `url(${props.aboutBackground}) no-repeat`,
    "background-attachment": "fixed",
    "-webkit-background-size": "cover",
    "-moz-background-size": "cover",
    "-o-background-size": "cover",
    "background-size": "cover",
  }
  let containerStyle = {
    ...style,
    ...overlayStyle,
  }
  return (
    <div style={containerStyle} className='aboutWhyUsContainer'>
      <h3 className='subtitle text-fullBlack font-bold'>{props.aboutTitle}</h3>
      <ul className='aboutWhyUsListWrapper'>
        <div>
          <p className='orderNumber'>1</p>
          <li className='aboutWhyUsListItem'>{props.listItem1}</li>
        </div>
        <div>
          <p className='orderNumber'>2</p>
          <li className='aboutWhyUsListItem'>{props.listItem2}</li>
        </div>
        <div>
          <p className='orderNumber'>3</p>
          <li className='aboutWhyUsListItem'>{props.listItem3}</li>
        </div>
      </ul>
    </div>
  )
}
export const DataTemplateExtraServices = props => {
  const [widthSize, setWidthSize] = useState("")
  function handleResize() {
    setWidthSize(window.innerWidth)
  }
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])
  return (
    <section>
      <div>
        {props.data.map(info => {
          return (
            <>
              <h2 className='text-[2rem] text-center font-subtitle text-[#e8e8e8] font-bold px-[10px]'>{info.name} Services We Provide</h2>
              <div
                style={(widthSize >= 640 && info.extraServices.length <= 3) || info.extraServices.length === 1 ? { justifyContent: "center" } : {}}
                className='flex gap-[20px] overflow-x-scroll w-[100%]'
              >
                {info.extraServices?.map(data => (
                  <a
                    className='mt-[10px] mb-[20px] min-w-[280px] max-w-[290px] flex justify-center items-center border hover:bg-[#0bd3d3] transition p-[20px] border-[#0bd3d3] text-center text-[1.8rem] text-[#f890e7] hover:text-black'
                    href={data.serviceRedirect}
                  >
                    {data.service}
                  </a>
                ))}
              </div>
            </>
          )
        })}
      </div>
    </section>
  )
}
export const DataTemplateCta = () => {
  return (
    <div className='pageCtaContainer'>
      <h3 className='subtitle text-superWhite'>Let us do the Work</h3>
      <Link to='/packages'>
        <p className='pageCta'>Check our package plans</p>
      </Link>
    </div>
  )
}
