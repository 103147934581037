import React from "react"
import "./Hero.css"
import { TitleAnimation } from "../TitleAnimation/TitleAnimation"

export const Hero = props => {
  let backgroundUrl = props.bgUrl
  let style = {
    background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),url('${backgroundUrl}')`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }
  const mainTitle = props.title
  return (
    <section style={style} className='pageHeroContainer'>
      <h3 className={mainTitle?.length > 17 ? "pageHeroTitleLong font-subtitle font-bold" : "pageHeroTitle font-subtitle font-bold"}>
        <TitleAnimation copy={mainTitle} role='header' />
      </h3>
    </section>
  )
}
