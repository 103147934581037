import React from "react"
import "../Styles/Hero.css"

export const Hero = () => {
  return (
    <>
      <section className='heroImg pt-[25vh]'>
        <div className='bg-[#1f1f1faa] w-[90%] mx-auto rounded-[20px] font-bold p-[5px] leading-tight md:w-[60%]'>
          <h1 className=' hidden'>ZenTech Media Solutions</h1>
          <h2 className='text-[1.3rem] uppercase text-white text-center mb-[5px] md:text-[2rem] lg:text-[2.3rem] xl:text-[3.2rem] font-primary'>
            Make your ideas possible through our Marketing and development solutions
          </h2>
          <p className='text-seaBlue text-center text-[1rem] md:text-[1.4rem] xl:text-[1.8rem] font-secondary'>
            We'll help you reach your goals and set new ones, together.
          </p>
        </div>
        <div className='scrollForMore'>
          <p className='text-hotPink'>Scroll</p>
          <span>&#9660;</span>
        </div>
      </section>
    </>
  )
}
