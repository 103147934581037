import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import ArticleData from "../../Blog/BlogData/Articles.json"

export const BlogNav = () => {
  const [toggle, setToggle] = useState(false)
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)

  const handleClick = () => {
    setToggle(!toggle)
  }
  useEffect(() => {
    setPosts(ArticleData)
    setLoading(false)
  }, [])
  return (
    <div>
      <section
        onClick={() => handleClick()}
        className='flex justify-center items-center w-[70px] h-[70px] rounded-[50%] text-[#e8e8e8] fixed bottom-0 right-0 z-50  bg-[#19ffc98c] mr-[10px] mb-[20px] cursor-pointer'
      >
        <h1 className='text-center text-[1.5rem]'>{toggle ? <span>&#9660;</span> : <span>&#9650;</span>}</h1>
      </section>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <section
          className={
            toggle
              ? "toggled-blog-nav fixed right-0 bottom-[110px] w-[280px] bg-[#000000ad] z-50 overflow-hidden"
              : "fixed h-0 right-0 bottom-[110px] w-[280px] bg-[#000000ad] z-50 overflow-hidden"
          }
        >
          <Link onClick={() => handleClick()} to='/blog/home'>
            <h3 className='text-[#62f7ff] hover:text-[#fb4bfe] text-center text-[1.8rem] bigAndSmallAnimation underline'>Blog &gt;</h3>
          </Link>
          {Object.keys(posts).map(postId => {
            const post = posts[postId]
            return (
              <div className='w-[280px] mx-auto' key={postId}>
                <div style={post.latestPost ? {} : { display: "none" }} className=' w-[100%] p-[10px] rounded-[10px] mx-auto mb-[20px]'>
                  <Link onClick={() => handleClick()} to={post.latestPost ? `/blog/${postId}` : ""}>
                    <h4 className='text-[1.1rem] text-center font-bold text-[#e8e8e8] '>Latest Article:</h4>
                    <img className='w-[50%] h-[80px] object-cover mx-auto mt-[5px]' src={post.latestPost ? post.thumbnail : ""} alt='' />
                    <h3 className='text-[1.1rem] text-center font-bold hover:text-[#fb4bfe] text-[#62f7ff]'>{post.latestPost ? post.title : ""}</h3>
                  </Link>
                </div>
              </div>
            )
          })}
        </section>
      )}
    </div>
  )
}
