import React from "react"
import { IndividualPackageInfo } from "./PackagesComponent/individualPackageInfo"

export const IndividualPackagePage = () => {
  return (
    <>
      <IndividualPackageInfo />
    </>
  )
}
