import "./TitleAnimation.css"
export const TitleAnimation = props => {
  return (
    <span aria-label={props.copy} role={props.role}>
      {props.copy?.split("").map((char, index) => {
        let style = { animationDelay: 0.5 + index / 20 + "s" }
        return (
          <span className='titleAnimation font-subtitle' key={index} aria-hidden='true' style={style}>
            {char}
          </span>
        )
      })}
    </span>
  )
}
