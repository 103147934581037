import React from "react"
import "../Styles/MeetOurTeam.css"
import { MeetOurTeamData } from "../../../Data/MeetOurTeamData"

export const MeetOurTeam = () => {
  return (
    <section className='meetOurTeamContainer'>
      <h4 className='subtitle text-superWhite font-subtitle'>Meet The Founders</h4>
      {MeetOurTeamData.map(info => (
        <>
          {info.zenTechLogo ? (
            <img className='zenTechLogo' src={info.zenTechLogo} alt='ZenTech Logo' />
          ) : (
            <div className='profileContainer'>
              <div className='profilePicWrapper'>
                <img className='profilePic' src={info.profilePic} alt='' />
                <img className='profileBadge' src={info.badge} alt='' />
                <div className='nameContainer'>
                  <h4 className='profileFirstName'>{info.firstName}</h4>
                  <h4 className='profileLastName'>{info.lastName}</h4>
                </div>
              </div>
              <h5 className='profileRole'>{info.role}</h5>
              <p className='profileBio'>{info.bio}</p>
            </div>
          )}
        </>
      ))}
    </section>
  )
}
