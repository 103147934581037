import React, { useState } from "react"
import { OurMission } from "../../../Data/MeetOurTeamData"
import computer from "../../../Assets/About/computerWhiteBg.jpeg"

export const LastSection = () => {
  const [data] = useState(OurMission[0])
  const split = data.description.split(".")
  const addBreaks = () => {
    const formattedSentences = split.map((sentence, index) => (
      <React.Fragment key={index} className='py-[20px]'>
        {index % 2 === 0 && index !== 0 && <br />}
        {sentence.trim() + "."}
      </React.Fragment>
    ))

    return formattedSentences
  }
  const formattedString = addBreaks()

  return (
    <div className='my-[5vh]'>
      <h1 className='text-white font-secondary text-center text-[2rem] lg:text-[2.5rem] xl:text-[3rem]'>{data.title}</h1>
      <img className=' w-[80%] md:w-[340px] xl:w-[500px] mx-auto my-[20px] rounded-[10px]' src={computer} alt='Computer on white background' />
      <p className='text-[#e8e8e8] px-[20px] bg-[#000000b3] w-[90%] mx-auto rounded-[20px] text-[1.1rem] text-center xl:text-[1.8rem]'>
        {formattedString}
      </p>
    </div>
  )
}
