import React, { useEffect, useState } from "react"
import "../Styles/Package.css"
import { PackagesData } from "../../../Data/PackagesData"
import IndividualPackageData from "../../../Data/individualPackageData.json"
import { Link } from "react-router-dom"

export const Package = () => {
  const [individualPackage, setIndividualPackage] = useState([])
  useEffect(() => {
    setIndividualPackage(IndividualPackageData)
  }, [])
  return (
    <div className='packageContainer'>
      <p className='text-[1.4rem] text-center text-[#e8e8e8] pt-[20px] font-subtitle'>
        If you are interested in individual Services{" "}
        <Link className='text-[#f890e7] hover:text-[#0bd3d3]' to='/contact-us'>
          Contact Us{" "}
        </Link>
        to get a customised package plan for your business needs.
      </p>
      <div className=' w-[100%] flex flex-wrap gap-[20px]'>
        {PackagesData.map(info => (
          <div className='bg-[#e8e8e8] w-[280px] mx-auto px-[10px] py-[15px] rounded-[20px]'>
            <h3 className='font-secondary text-[2.4rem] text-center text-[#0bd3d3] font-bold'>{info.name}</h3>
            <p className='text-[2rem] text-center text-[#0bd3d3] font-bold'>{info.price}</p>
            <ul className=' line-clamp-[10]'>
              {info.keyPoints.map(list => (
                <li className='ml-[20px] list-disc text-[1.1rem] '>{list}</li>
              ))}
            </ul>
            {Object.keys(individualPackage).map(packageId => {
              return (
                <div
                  style={info.title === packageId ? { display: "" } : { display: "none" }}
                  className='w-fit mb-[5px] mt-[10px] mx-auto rounded-[10px]'
                >
                  <Link to={`/packages/${packageId}`}>
                    <p className='px-[10px] py-[5px] bg-[#f890e7] hover:bg-[#0bd3d3] rounded-[10px] duration-[.3s] hover:scale-[1.1]'>More Info</p>
                  </Link>
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}
