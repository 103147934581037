import React, { useEffect, useState } from "react"
import blogBg from "../../Assets/Backgrounds/coolTech.png"
import { Link } from "react-router-dom"
import ArticleData from "../BlogData/Articles.json"

export const BlogHero = () => {
  const [posts, setPosts] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setPosts(ArticleData)
    setLoading(false)
  }, [])
  const style = {
    background: `linear-gradient(to right, rgba(0,0,0,.1), rgba(0,0,0,.1)), url(${blogBg})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  }
  return (
    <section style={style} className='py-[20px] flex justify-center items-center'>
      <div>
        <h1 className='text-[3rem] text-[#e8e8e8] font-bold font-primary text-center'>
          <span className='text-seaBlue'>Zen</span>
          <span className='text-hotPink'>Tech</span> <br /> Tech Talks
        </h1>
        {loading ? (
          <p className='text-white'>Loading...</p>
        ) : (
          <div className='text-[#e8e8e8] text-center  bg-[#00000094] py-[5px] px-[15px] rounded-[15px]'>
            <h2 className='text-[1.3rem] font-bold'>Check out our latest Article :</h2> <br />
            {Object.keys(posts).map(postId => {
              const post = posts[postId]
              return (
                <div className='w-[280px] mx-auto' key={postId}>
                  <div
                    style={post.latestPost ? {} : { display: "none" }}
                    className=' w-[50%] p-[10px] rounded-[10px] mx-auto mb-[20px] text-[#e8e8e8] bg-[#36f2ff7c] hover:text-[#000000]'
                  >
                    <Link to={post.latestPost ? `/blog/${postId}` : ""}>
                      <img className='w-[100%] h-[100px] object-cover mx-auto' src={post.latestPost ? post.thumbnail : ""} alt='' />
                      <h3 className='text-[1.1rem] font-bold '>{post.latestPost ? post.title : ""}</h3>
                    </Link>
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </section>
  )
}
