import React, { useEffect, useState } from "react"
import singlePackage from "../../../Data/individualPackageData.json"
import { useParams } from "react-router-dom"
import { Hero } from "../../../Components/Hero/Hero"

export const IndividualPackageInfo = () => {
  const { id } = useParams()
  const [data, setData] = useState(null)
  useEffect(() => {
    if (singlePackage[id]) {
      setData(singlePackage[id])
    }
  }, [id])
  console.log("ID", data?.title)
  return (
    <section className='mt-[10vh] bg-[#000000c6]'>
      <Hero title={data?.title} bgUrl='https://images.pexels.com/photos/669619/pexels-photo-669619.jpeg?' />
      <p className='text-[#e8e8e8] w-[90%] mx-auto text-[1.5rem] p-[5px] text-center'>{data?.description}</p>
      <img className='w-[100%] h-[300px] object-cover lg:w-[400px] lg:mx-auto' src={data?.img} alt='boxes' />
      <ul className=' py-[15px]'>
        {data?.list.map(info => (
          <li className='text-[#0bd3d3] list-disc ml-[10px] my-[10px] text-[1.2rem] lg:w-[50%] lg:mx-auto'>{info}</li>
        ))}
      </ul>
    </section>
  )
}
