import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import articleData from "../BlogData/Articles.json"

export const FeaturedArticles = () => {
  const [featuredArticle, setFeaturedArticle] = useState([])
  useEffect(() => {
    setFeaturedArticle(articleData)
  }, [])
  return (
    <section>
      <h2 className='subtitle text-white font-secondary'>Featured Articles</h2>
      <div className='flex w-[80%] mx-auto flex-wrap gap-[40px]'>
        {Object.keys(featuredArticle).map(postId => {
          const post = featuredArticle[postId]
          return (
            <>
              {post.featured && (
                <div key={postId} className=' w-[100%] items-center md:w-[45%] md:mx-auto'>
                  <Link to={`/blog/${postId}`}>
                    <div className='flex items-center  border bg-[#0befff41] rounded-[20px] p-[15px]'>
                      <img
                        className='w-[30%]  h-[60px] object-cover md:h-[100px] rounded-[10px] lg:h-[120px] xl:h-[160px]'
                        src={post.thumbnail}
                        alt={post.thumbnailAlt}
                      />
                      <h2 className=' w-[65%] text-white mx-auto text-center underline'>{post.title}</h2>
                    </div>
                  </Link>
                </div>
              )}
            </>
          )
        })}
      </div>
    </section>
  )
}
