import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { ExtraServicesTemplate } from "../../Components/ExtraServicesTemplate/ExtraServicesTemplate"
import { BrandingData } from "../../Data/ExtraServicesData"
import { Helmet } from "react-helmet-async"

export const Branding = () => {
  return (
    <>
      <Helmet>
        <title>Branding</title>
        <link rel='canonical' href='/digital-assets/branding' />
        <meta
          name='description'
          content='We provide an array of options to help with branding, from creating a brand to expanding a brand. We can even help with rebranding to help your business reach its full potential.'
        />
      </Helmet>
      <section>
        <Hero title='Branding' bgUrl='https://images.pexels.com/photos/1601773/pexels-photo-1601773.jpeg?' />
        <ExtraServicesTemplate data={BrandingData} />
      </section>
    </>
  )
}
