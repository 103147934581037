import "./App.css"
import { HashRouter as Router, Routes, Route } from "react-router-dom"
import { Home } from "./Views/HomeView/Home"
import { Navbar } from "./Components/Navbar/Navbar"
import { WebDev } from "./Views/WebDev/WebDev"
import { DigitalAssets } from "./Views/DigitalAssets/DigitalAssets"
import { Marketing } from "./Views/Marketing/Marketing"
import { Packages } from "./Views/Packages/Packages"
import { OurMission } from "./Views/OurMission/OurMission"
import { ScrollToTop } from "./ScrollToTop"
import { Contact } from "./Views/ContactView/Contact"
import { SocialMedia } from "./Views/ExtraServices/SocialMedia"
import { LandingPage } from "./Views/ExtraServices/LandingPage"
import { Branding } from "./Views/ExtraServices/Branding"
import { WebDesign } from "./Views/ExtraServices/WebDesign"
import { SalesOptimization } from "./Views/ExtraServices/SalesOptimization"
import { SearchEngineOptimization } from "./Views/ExtraServices/SearchEngineOptimization"
import { Footer } from "./Components/Footer/Footer"
import { HelmetProvider } from "react-helmet-async"
import { BlogHome } from "./Blog/BlogHome"
import { BlogPost } from "./Blog/BlogPost/BlogPost"
import { BlogNav } from "./Components/BlogNav/BlogNav"
import { IndividualPackagePage } from "./Views/Packages/IndividualPackagePage"
import { BlogListing } from "./Blog/BlogListing/BlogListing"

function App() {
  return (
    <>
      <Router>
        <HelmetProvider>
          <Navbar />
          <BlogNav />
          <ScrollToTop />
          <Routes>
            <Route exact path='/' element={<Home />} />
            <Route exact path='/web-development' element={<WebDev />} />
            <Route exact path='/digital-assets' element={<DigitalAssets />} />
            <Route exact path='/marketing' element={<Marketing />} />
            <Route exact path='/packages' element={<Packages />} />
            <Route exact path='/packages/:id' element={<IndividualPackagePage />} />
            <Route exact path='/about' element={<OurMission />} />
            <Route exact path='/contact-us' element={<Contact />} />
            <Route exact path='/marketing/social-media-management' element={<SocialMedia />} />
            <Route exact path='/web-development/landing-pages' element={<LandingPage />} />
            <Route exact path='/digital-assets/branding' element={<Branding />} />
            <Route exact path='/web-development/web-design' element={<WebDesign />} />
            <Route exact path='/marketing/conversion-rate-optimization' element={<SalesOptimization />} />
            <Route exact path='/web-development/search-engine-optimization' element={<SearchEngineOptimization />} />
            <Route exact path='/blog/home' element={<BlogHome />} />
            <Route exact path='/blog/listing' element={<BlogListing />} />
            <Route exact path='/blog/:id' element={<BlogPost />} />
          </Routes>
          <Footer />
        </HelmetProvider>
      </Router>
    </>
  )
}

export default App
