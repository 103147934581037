import React from "react"
import instaLogo from "../../../Assets/SocialMediaLogos/Instagram_logo_2016.svg"

export const SocialMedia = () => {
  const images = [
    {
      image: instaLogo,
    },
  ]
  return (
    <section className='py-[40px]'>
      <h3 className='pb-[20px] text-[3rem] capitalize text-superWhite text-center font-subtitle'>Follow us on Social Media</h3>
      <div className='flex flex-wrap justify-center gap-[40px]'>
        {images.map(info => (
          <a key={info.image} target='_blank' rel='noreferrer' href='https://www.instagram.com/zentechmediasolutions/'>
            <img className='w-[60px] hover:scale-[1.4] hover:rotate-[360deg] duration-[.5s]' src={info.image} alt='' />
          </a>
        ))}
      </div>
    </section>
  )
}
