import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import ArticlesData from "../BlogData/Articles.json"
import { Hero } from "../../Components/Hero/Hero"
import { Helmet } from "react-helmet"

export const BlogPost = () => {
  const { id } = useParams()
  const [post, setPost] = useState(null)

  useEffect(() => {
    if (ArticlesData[id]) {
      setPost(ArticlesData[id])
    }
  }, [id])

  if (!post) {
    return <div>Loading...</div>
  }

  return (
    <>
      <Helmet>
        <title>{post.title}</title>
        <link rel='canonical' href='/blog/home' />
        <link rel='icon' href={post.thumbnail} />
        <link rel='apple-touch-icon' href={post.thumbnail} />
        <meta property='og:type' content='website' />
        <meta property='og:title' content='ZenTech Tech Talks' />
        <meta property='og:description' content={post.shortDescription} />
        <meta property='og:image' content={post.thumbnail} />
        <meta property='og:url' content='https://www.zentechmediasolutions.com/blog/home' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta name='twitter:title' content='ZenTech Tech Talks' />
        <meta name='twitter:description' content={post.shortDescription} />
        <meta name='twitter:image' content={post.thumbnail} />
        <meta name='description' content={post.shortDescription} />
      </Helmet>
      <div className='mt-[10vh] pb-[5vh] bg-[#000000b5]'>
        <Hero className='text-center' title={post.title} bgUrl={post.thumbnail} />
        <section className='text-[#e8e8e8] w-[90%] mx-auto my-[30px] gap-[30px]'>
          <p className=' italic text-[.8rem] pl-[10px] pb-[15px] lg:text-[1.1rem]'>Published: {post.fullDate}</p>
          <p className='text-[1.1rem] lg:text-[1.4rem]'>{post.partOne}</p>
          <div className=' md:w-[300px] mx-auto my-[20px]'>
            <img className=' md:w-[300px] mx-auto ' src={post.imageOne} alt={post.imageOneAlt} />
            <p className='text-[#676767] text-center text-[.8rem]'>
              Photo Credits:{" "}
              <a className='underline text-[#737373]' rel='noreferrer' target='_blank' href={post.imageOneCreditUrl}>
                {post.imageOneCredit}
              </a>
            </p>
          </div>
          <p className='text-[1.1rem] lg:text-[1.4rem]'>{post.partTwo}</p>
          <div className=' md:w-[300px] mx-auto my-[20px]'>
            <img className=' md:w-[300px] mx-auto ' src={post.imageTwo} alt={post.imageTwoAlt} />
            <p className='text-[#676767] text-center text-[.8rem]'>
              Photo Credits:{" "}
              <a className='underline text-[#737373]' rel='noreferrer' target='_blank' href={post.imageTwoCreditUrl}>
                {post.imageTwoCredit}
              </a>
            </p>
          </div>
          <p className='text-[1.1rem] lg:text-[1.4rem]'>{post.partThree}</p>
          <div className=' md:w-[300px] mx-auto my-[20px]'>
            <img className=' md:w-[300px] mx-auto ' src={post.imageThree} alt={post.imageThreeAlt} />
            {post.imageThree && (
              <p className='text-[#676767] text-center text-[.8rem]'>
                Photo Credits:{" "}
                <a className='underline text-[#737373]' rel='noreferrer' target='_blank' href={post.imageThreeCreditUrl}>
                  {post.imageThreeCredit}
                </a>
              </p>
            )}
          </div>
          <p className='text-[1.1rem] lg:text-[1.4rem]'>{post.partFour}</p>
        </section>
      </div>
    </>
  )
}
