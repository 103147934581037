import React from "react"
import { Hero } from "../../Components/Hero/Hero"
import { ExtraServicesTemplate } from "../../Components/ExtraServicesTemplate/ExtraServicesTemplate"
import { LandingPageData } from "../../Data/ExtraServicesData"
import { Helmet } from "react-helmet-async"

export const LandingPage = () => {
  return (
    <section>
      <Helmet>
        <title>Landing Pages</title>
        <link rel='canonical' href='/web-development/landing-pages' />
        <meta
          name='description'
          content='At ZenTech we help get more reach and funnel traffic to your website using landing pages. Our team is always ready to plan ways to meet your business needs.'
        />
      </Helmet>
      <Hero title='Landing Pages' bgUrl='https://images.pexels.com/photos/326508/pexels-photo-326508.jpeg?' />
      <ExtraServicesTemplate data={LandingPageData} />
    </section>
  )
}
