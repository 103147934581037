export const MeetOurTeamData = [
  {
    firstName: `Olmen`,
    lastName: `Vicente`,
    profilePic: require("../Assets/OlmenProfilePic.JPG"),
    role: `Co-Founder`,
    bio: `As a co-founder of ZenTech Media Solutions, Olmen Vicente is a visionary leader with a passion for harnessing the power of technology and creativity to transform businesses in the digital age. With a background in web development, Olmen Vicente brings a wealth of expertise and innovation to the company's mission of providing comprehensive online services. `,
    badge: `https://cdn-icons-png.flaticon.com/512/1011/1011322.png`,
  },
  {
    zenTechLogo: require("../Assets/zenTechLogo1.png"),
  },
  {
    firstName: `Kenneth`,
    lastName: `Riveron`,
    profilePic: require("../Assets/KennethProfilePic.JPG"),
    role: `Co-Founder`,
    bio: `As a co-founder of ZenTech Media Solutions, Kenneth Riveron brings a unique blend of creativity and technical acumen to the forefront of the company's mission. With a background in web development, Kenneth Riveron is a driving force behind ZenTech's dedication to providing cutting-edge online services that empower businesses in the digital era.`,
    badge: `https://cdn-icons-png.flaticon.com/512/263/263142.png`,
  },
]
export const MeetOurPartnersData = [
  {
    firstName: `Joel`,
    lastName: `Garcia`,
    profilePic: require("../Assets/JoelProfilePic.jpeg"),
    role: `Graphic Designer`,
    bio: `Our partner from Miami Lights Studio has a passion for creativity and a keen eye for detail, Joel excels in creating visually stunning and user-friendly products that meet the needs of our customers. He is dedicated to staying on top of design trends and technologies and is committed to pushing the boundaries of design innovation.`,
    badge: `https://cdn-icons-png.flaticon.com/512/103/103410.png`,
    redirect: `https://www.miamilightsstudio.co/`,
    companyName: "Miami Lights Studio",
  },
]
export const OurMission = [
  {
    title: "Our Mission",
    img: "",
    description:
      "We love what we do and we want you to share in our mission to create a bigger online presence in this everchanging digital world. We want to work with companies that have the desire to grow and open doors to a new world. The first step is to have an open mind and try new things. We want to give you the opportunity to grow and create an online presence for your clients to see. We offer a variety of services that cater to your business needs. We are here for the long run, making sure that you are in great hands.",
  },
]
