import React from "react"
import { PackageForm } from "../../Components/Forms/PackageForm"
import { Helmet } from "react-helmet-async"

export const Contact = () => {
  return (
    <>
      <Helmet>
        <title>Contact Us</title>
        <meta name='robots' content='noindex' />
        <link rel='canonical' href='/contact-us' />
      </Helmet>
      <div className='contactPageContainer'>
        <PackageForm />
      </div>
    </>
  )
}
