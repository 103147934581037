export const WhatWeDoData = [
  {
    title: "Branding Strategy",
    img: require("../Assets/Icons/megaphoneBlue.PNG"),
    imgHover: require("../Assets/Icons/megaphoneBlack.PNG"),
    url: "/digital-assets/branding",
    description: `Branding means more than just a logo, it's creating a strategy and a story. We help unlock the full potential of your brand.`,
  },
  {
    title: "Web Design",
    img: require("../Assets/Icons/webDesignBlue.PNG"),
    imgHover: require("../Assets/Icons/webDesignBlack.PNG"),
    url: "/web-development/web-design",
    description:
      "Our designs are made with your brand and story in mind. We want to make it an extension of your business that not only attracts but also retains users.",
  },
  {
    title: "Social Media Management",
    img: require("../Assets/Icons/chartBlue.PNG"),
    imgHover: require("../Assets/Icons/chartBlack.PNG"),
    url: "/marketing/social-media-management",
    description:
      "Community engagement, analytics, and strategy helps build brand awareness. Creating a social media presence is vital for reaching your business goals.",
  },
  {
    title: "Landing Pages",
    img: require("../Assets/Icons/webDevBlue.PNG"),
    imgHover: require("../Assets/Icons/webDevBlack.PNG"),
    url: "/web-development/landing-pages",
    description: "Improve conversion rates and reach your traget audience by having landing pages that offer great user experience.",
  },
  {
    title: "Sales Optimization",
    img: require("../Assets/Icons/brainGearBlue.PNG"),
    imgHover: require("../Assets/Icons/brainGearBlack.PNG"),
    url: "/marketing/conversion-rate-optimization",
    description: "Whether you provide a service or a product we will help find ways to reach new clients and retain old ones.",
  },
  {
    title: "SEO",
    img: require("../Assets/Icons/magnifyBlue.PNG"),
    imgHover: require("../Assets/Icons/magnifyBlack.PNG"),
    url: "/web-development/search-engine-optimization",
    description: `Search Engine Optimization is everchanging, we make sure to stay on top of trends to help clients rank higher.`,
  },
]
